import {
  ILazyPaginationConfiguration,
  IPaginationConfiguration,
  IResponse,
} from "../Interfaces/Interfaces";
import { Constants } from "./Constants";
import $ from "jquery";
import axios from "axios";

export class Common {
    static hidePageScroll() {
    const htmlElem = document.querySelector("html");
    htmlElem!.style.overflow = "hidden";
  }
  static showPageScroll() {
    const htmlElem = document.querySelector("html");
    htmlElem!.style.overflow = "auto";
  }
  static getSelectedValue(ReportID: string) {
    let selected;
    if (
      Constants.PlanningCuratedTab &&
      Constants.PlanningReportsLoaded.indexOf("Curated") < 0 &&
      ReportID == "Curated"
    ) {
      //Constants.PlanningReportsLoaded.push('Curated');
      selected = "Curated";
    } else if (
      Constants.PlanningReportTab &&
      Constants.PlanningReportsLoaded.indexOf("AdhocReport") < 0 &&
      ReportID == "AdhocReport"
    ) {
      Constants.PlanningReportsLoaded.push("AdhocReport");
      selected = "AdhocReport";
    } else if (
      Constants.PlanningReportTab &&
      Constants.PlanningReportsLoaded.indexOf("AdhocReportGrid") < 0 &&
      ReportID == "AdhocReportGrid"
    ) {
      Constants.PlanningReportsLoaded.push("AdhocReportGrid");
      selected = "AdhocReportGrid";
    } else if (
      Constants.PlanningReportsLoaded.indexOf("FullMetricReport") < 0 &&
      ReportID == "FullMetricReport"
    ) {
      //Constants.reportsLoaded.push('FullMetricReport');
      selected = "FullMetricReport";
    } else if (
      Constants.PlanningEditTab &&
      Constants.PlanningReportsLoaded.indexOf("ManagedReportPBI") < 0 &&
      ReportID == "ManagedReportPBI"
    ) {
      selected = "ManagedReportPBI";
    } else if (
      Constants.PlanningEditTab &&
      Constants.PlanningReportsLoaded.indexOf("UnManagedReportPBI") < 0 &&
      ReportID == "UnManagedReportPBI"
    ) {
      selected = "UnManagedReportPBI";
    } else if (
      Constants.SurfaceReportTab &&
      Constants.SurfaceReportsLoaded.indexOf("AdhocReportSurface") < 0 &&
      ReportID == "AdhocReportSurface"
    ) {
      Constants.SurfaceReportsLoaded.push("AdhocReportSurface");
      selected = "AdhocReportSurface";
    } else if (
      Constants.SurfaceReportTab &&
      Constants.SurfaceReportsLoaded.indexOf("AdhocReportGridSurface") < 0 &&
      ReportID == "AdhocReportGridSurface"
    ) {
      Constants.SurfaceReportsLoaded.push("AdhocReportGridSurface");
      selected = "AdhocReportGridSurface";
    } else if (
      Constants.SurfaceEditTab &&
      Constants.SurfaceReportsLoaded.indexOf("ManagedReportPBISurface") < 0 &&
      ReportID == "ManagedReportPBISurface"
    ) {
      selected = "ManagedReportPBISurface";
    } else if (
      Constants.SurfaceEditTab &&
      Constants.SurfaceReportsLoaded.indexOf("UnManagedReportPBISurface") < 0 &&
      ReportID == "UnManagedReportPBISurface"
    ) {
      selected = "UnManagedReportPBISurface";
    } else if (ReportID == "PinReportTab" && Constants.currview == "Report") {
      selected = "PinReportTab";
    } else if (ReportID == "PinEditTab" && Constants.currview == "Edit") {
      selected = "PinEditTab";
    } else if (
      ReportID == "PinReportGlobalPartnerTab" &&
      Constants.currview == "GlobalPartner"
    ) {
      selected = "PinReportGlobalPartnerTab";
    } else if (
      ReportID == "PinReportPartnerTab" &&
      Constants.currview == "Partner"
    ) {
      selected = "PinReportPartnerTab";
    }
    return selected;
  }
  static appInsightsObject: any;
  static applicationInsights: any;
  static getPageConfig(
    totalItems: number,
    currentPage: number,
    pageSize?: number
  ): IPaginationConfiguration {
    const paginationSize = Constants.PAGINATION_SIZE_DEFAULT;
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage, endPage;
    if (totalPages <= paginationSize) {
      // less than 5 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 5 total pages so calculate start and end pages
      if (currentPage <= Math.floor(paginationSize / 2) + 1) {
        startPage = 1;
        endPage = paginationSize;
      } else if (
        currentPage + (Math.ceil(paginationSize / 2) - 1) >=
        totalPages
      ) {
        startPage = totalPages - paginationSize + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.ceil(paginationSize / 2) + 1;
        endPage = currentPage + Math.floor(paginationSize / 2);
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    const totalPagesFormatted = Common.FormatNumber(totalPages, 3);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      totalPagesFormatted: totalPagesFormatted,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
  static instrumentationKey() {
    return (<HTMLInputElement>document.getElementById("InstrumentationKey"))?.value;
  }

  static getPageConfigLazy(
    totalPages: number,
    currentPage: number
  ): ILazyPaginationConfiguration {
    const paginationSize = Constants.PAGINATION_SIZE_DEFAULT;
    // default to first page
    currentPage = currentPage || 1;

    let startPage, endPage;
    if (totalPages <= paginationSize) {
      // less than 5 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 5 total pages so calculate start and end pages
      if (currentPage <= Math.floor(paginationSize / 2) + 1) {
        startPage = 1;
        endPage = paginationSize;
      } else if (
        currentPage + (Math.ceil(paginationSize / 2) - 1) >=
        totalPages
      ) {
        startPage = totalPages - paginationSize + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.ceil(paginationSize / 2) + 1;
        endPage = currentPage + Math.floor(paginationSize / 2);
      }
    }

    // create an array of pages to ng-repeat in the pager control
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    const totalPagesFormatted = Common.FormatNumber(totalPages, 3);
    // return object with all pager properties required by the view
    return {
      currentPage: currentPage,
      totalPages: totalPages,
      totalPagesFormatted: totalPagesFormatted,
      startPage: startPage,
      endPage: endPage,
      pages: pages,
    };
  }
  static checkResponse(response: IResponse): string {
    switch (response.status) {
      case Constants.HTTP_STATUS.OK:
        return Constants.STRING_EMPTY;
      case Constants.HTTP_STATUS.NOTFOUND:
        return Constants.NO_DATA_MESSGAE;
      case Constants.HTTP_STATUS.BADREQUEST:
        return Constants.BAD_REQUEST_MESSAGE;
      case Constants.HTTP_STATUS.SERVERERROR:
        return Constants.SERVER_ERROR_MESSAGE;
      case Constants.HTTP_STATUS.NOCONTENT:
        return Constants.NO_DATA_MESSGAE;
      default:
        return Constants.ERROR_MESSAGE;
    }
  }
  static parseTokenData() {
    return localStorage.getItem("userTokens")
      ? JSON.parse(localStorage.getItem("userTokens") as string)
      : null;
  }
  static istokenExpired(tokenType: string) {
    const tokenConfig = Common.parseTokenData();
    if (tokenConfig !== null) {
      const tokenExpiry = new Date(tokenConfig[tokenType + "TokenExpires"]);
      //check if the token expiry time is less than or equal to 2 minutes
      if (tokenExpiry.getTime() - Date.now() < 120000) {
        return true; //Return true if token is about to expire
      } else {
        return false; //Return false if token will not expire in next 2 minutes
      }
    } else {
      return true; //Return true if no token is found in localStorage
    }
  }
  static getToken(thisTokentype: string) {
    const tokenLocalConfig = Common.parseTokenData();
    return tokenLocalConfig && tokenLocalConfig[thisTokentype + "AccessToken"];
  }
  static updateTokenData(tokenInfo: any) {
    const PowerBIRedirect = tokenInfo.data["PowerBIRedirect"];
    ////console.log("Common - updateTokenData -" + JSON.stringify(tokenInfo.data));
    if (PowerBIRedirect && PowerBIRedirect.length > 0) {
      window.open(PowerBIRedirect, "_self");
    } else {
      localStorage.setItem("userTokens", JSON.stringify(tokenInfo.data));
    }
  }
  static checkSessionExpiry(tokenInfo: any) {
    if (
      tokenInfo.config.headers &&
      tokenInfo.config.headers.location &&
      tokenInfo.config.headers.location.indexOf("login") !== -1
    ) {
      ////console.log("Common - checkSessionExpiry - True");
      return true;
    } else {
      ////console.log("Common - checkSessionExpiry - False");
      return false;
    }
  }
  static reloadSession() {
    if (
      window.localStorage.getItem("count") == null ||
      window.localStorage.getItem("count") == undefined
    ) {
      window.localStorage.setItem("count", "1");
    }
    const count = parseInt(window.localStorage.getItem("count") || "0") + 1;
    if (count <= 5) {
      ////console.log("Common - Reloading Page - Count: " + count);
      window.location.assign(window.location.href);
    } else if (count > 5) {
      ////console.log("Common - Reload Count > 5 - Redirecting to Error Page");
      window.location.assign("/Error/Error");
    }
  }
  static getControllerName() {
    const currentCtrl = window.location.pathname.split("/")[1];
    if (currentCtrl.toLowerCase() === "pattool") {
      const controllerNameArray = window.location.pathname.split("/");
      if (controllerNameArray.length > 2 && controllerNameArray[2] != "")
        return window.location.pathname.split("/")[2].toLowerCase();
      return window.location.pathname.split("/")[1].toLowerCase();
    } else if (currentCtrl.length === 0) {
      return "home";
    } else {
      return currentCtrl.toLowerCase();
    }
  }
  static fetchGetComponents() {
    if (window.location.search) {
      let usefulParam = window.location.search.substring(1),
        query = usefulParam || "",
        vars = query.split("&");
      const query_string: { [key: string]: string | string[] } = {};
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
          query_string[pair[0].toLowerCase()] = decodeURIComponent(pair[1]);
          // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
          const arr: string[] = [
            query_string[pair[0]] as string,
            decodeURIComponent(pair[1]),
          ];
          query_string[pair[0].toLowerCase()] = arr;
          // If third or later entry with this name
        } else {
          (query_string[pair[0].toLowerCase()] as string[]).push(
            decodeURIComponent(pair[1])
          );
        }
      }
      return query_string;
    } else {
      return null;
    }
  }
  static fetchValidParameter() {
    const params = Common.fetchGetComponents();
    if (params) {
      if (params.hasOwnProperty("partneroneid")) {
        return [Constants.PARTNER_ONE_ID_QUERY_KEY, params["partneroneid"]];
      } else if (params.hasOwnProperty("partnerid")) {
        return [Constants.PARTNER_ID_QUERY_KEY, params["partnerid"]];
      } else {
        return null;
      }
    }
  }
  static getURLQueryParameters(): any {
    const parameterArray: any = {};
    if (window.location.search !== "") {
      const queryStringArray = window.location.search.substr(1).split("&");
      if (queryStringArray[0] === "") return {};
      for (let index = 0; index < queryStringArray.length; ++index) {
        const parameter = queryStringArray[index].split("=");
        if (parameter.length > 1) {
          parameterArray[parameter[0].toLowerCase()] = decodeURIComponent(
            parameter[1].replace(/\+/g, " ")
          );
        }
      }
    }
    return parameterArray;
  }

  static assignPartnerOnboardingURL() {
    const URL = window.location.host.split(".")[0];
    switch (URL) {
      case "gpsinsights":
        Constants.AUTO_JOIN_LINK = "https://aka.ms/joinGPSInsights";
        Constants.O365_GROUPID = "be4af5f0-770e-4e90-b7f0-9e04c864d7a8";
        break;
      case "gpsinsightsuat":
        Constants.AUTO_JOIN_LINK = "https://aka.ms/joinGPSInsightsUAT";
        Constants.O365_GROUPID = "6fbd5824-5073-45a2-87c3-ee7e1dd57900";
        break;
      case "onecommercialpartnerdev-test":
        Constants.AUTO_JOIN_LINK = "https://aka.ms/joinGPSInsightstest";
        Constants.O365_GROUPID = "033bea35-a2ee-45bb-b2b6-bca9587c018b";
        break;
      default:
        Constants.AUTO_JOIN_LINK = "https://aka.ms/joinGPSInsightsdev";
        Constants.O365_GROUPID = "9fa7ebd7-7817-4f61-81de-44ea6b2df70c";
        break;
    }
  }

  static queryParameters: any = Common.getURLQueryParameters();
  static setQueryParameters(tab: string, subTab: string) {
    let queryString: string = "";
    for (const property in Common.queryParameters) {
      if (
        Common.queryParameters.hasOwnProperty(property) &&
        property.toLowerCase() !== "tab" &&
        property.toLowerCase() !== "subtab"
      ) {
        queryString =
          property.toString() +
          "=" +
          Common.queryParameters[property] +
          "&" +
          queryString;
      }
    }
    if (tab == "Edit") {
      tab = "Planning";
    }
    if (tab != "OCPLanding") {
      queryString = queryString + "tab=" + tab;
    }
    if (subTab !== "") {
      queryString = queryString + "_" + subTab;
    }
    queryString = window.location.pathname + "?" + queryString;
    this.updateURLString(queryString);
  }
  static updateQueryParameters(key: string, value: string) {
    let queryString: string = "";
    for (const property in Common.queryParameters) {
      if (property.toLowerCase() == key) {
        queryString = queryString + property.toString() + "=" + value + "&";
      } else {
        queryString =
          queryString +
          property.toString() +
          "=" +
          Common.queryParameters[property] +
          "&";
      }
    }
    if (queryString.charAt(queryString.length - 1) == "&") {
      queryString = queryString.substring(0, queryString.length - 1);
    }
    queryString = window.location.pathname + "?" + queryString;
    this.updateURLString(queryString);
  }
  static updateURLString(url: string) {
    window.history.replaceState({}, "", url);
  }
  static init = $(document).ready(function () {

    // setTimeout(() => {
    //   Common.appInsightsObject = new ApplicationInsights(snippet);
    //   Common.applicationInsights = Common.appInsightsObject.loadAppInsights();
    // }, 1000);
  });
  static processHFPFilterCount() {
    let partnerOneConfig =
        Constants.SINGLE_PARTNER_HTML_GRIDS_CONFIG["PartnerOneIDFilterCount"],
      partnerSubconfig =
        Constants.SINGLE_PARTNER_HTML_GRIDS_CONFIG["PartnerIDFilterCount"],
      partnerOneFilter =
        partnerOneConfig["parameters"][
          partnerOneConfig["parameters"].length - 1
        ],
      partnerSubFilter =
        partnerSubconfig["parameters"][
          partnerSubconfig["parameters"].length - 1
        ],
      filterModel: any = Constants.PARTNER_SEARCH_FILTER_MODEL,
      tempString = "";
    const repeatedcols = [
      "[PartnerTypeID_1]",
      "[PartnerSubTypeID_1]",
      "[IndustryID_1]",
      "[VerticalID_1]",
    ];
    const execeptedParameters = [
      "pSortBy",
      "pSortOrder",
      "pReportID",
      "pDrillDown",
    ];
    for (const key in partnerSubconfig["parameters"]) {
      if (
        execeptedParameters.indexOf(partnerSubconfig["parameters"][key].Key) < 0
      ) {
        partnerSubconfig["parameters"][key].Value = [[null]];
        partnerOneConfig["parameters"][key].Value = [[null]];
      }
    }

    for (const keyCol in filterModel) {
      if (
        filterModel[keyCol as keyof typeof filterModel] &&
        keyCol !== "[CloudSubscriptionSaleProduct]" &&
        keyCol !== "[CloudConsumptionorActiveUseProduct]" &&
        keyCol.charAt(0) === "["
      ) {
        if (tempString.length) {
          if (
            keyCol === "[HasActiveCloudSubscriptionSale]" &&
            filterModel[keyCol] === "(1)"
          ) {
            if (!filterModel["[CloudSubscriptionSaleProduct]"]) {
              tempString +=
                " AND ( [HasDynamicCRMOnlineSubscriptions] = 1 OR [HasOffice365Subscriptions] = 1 OR [HasWindowsIntuneSubscriptions] = 1 OR [HasOtherSubscriptions] = 1)";
              continue;
            } else {
              var parameters = filterModel["[CloudSubscriptionSaleProduct]"];
              String(parameters).replace("(", "").replace(")", "").split(",");
              tempString +=
                " AND ( " + Common.populateparamersSalesProduct(parameters);
              continue;
            }
          } else if (
            keyCol === "[HasActiveCloudSubscriptionSale]" &&
            filterModel[keyCol] === "(2)"
          ) {
            tempString +=
              " AND ( [HasDynamicCRMOnlineSubscriptions] = 0 AND [HasOffice365Subscriptions] = 0 AND [HasWindowsIntuneSubscriptions] = 0 AND [HasOtherSubscriptions] = 0)";
            continue;
          }

          if (
            keyCol === "[HasCloudConsumptionorActiveUse]" &&
            filterModel[keyCol] === "(1)"
          ) {
            if (!filterModel["[CloudConsumptionorActiveUseProduct]"]) {
              tempString +=
                "  AND ( [HasAADPActiveUse] = 1 OR [HasAzureConsumption] = 1 OR [HasEnterpriseMobilitySuiteActiveUse] = 1 OR [HasDynamicsCRMOnlineActiveUse] = 1 OR [HasOffice365ActiveUse] = 1 OR [HasPowerBIActiveUse] = 1 OR [HasWindowsIntuneActiveUse] = 1 OR [HasAzIPActiveUse] = 1)";
              continue;
            } else {
              var parameters =
                filterModel["[CloudConsumptionorActiveUseProduct]"];
              String(parameters).replace("(", "").replace(")", "").split(",");
              tempString +=
                " AND ( " + Common.populateparamersActiveUseProduct(parameters);
              continue;
            }
          } else if (
            keyCol === "[HasCloudConsumptionorActiveUse]" &&
            filterModel[keyCol] === "(2)"
          ) {
            tempString +=
              " AND ( [HasAADPActiveUse] = 0 AND  [HasAzureConsumption] = 0 AND  [HasEnterpriseMobilitySuiteActiveUse] = 0 AND  [HasDynamicsCRMOnlineActiveUse] = 0 AND  [HasOffice365ActiveUse] = 0 AND  [HasPowerBIActiveUse] = 0 AND  [HasWindowsIntuneActiveUse] = 0 AND  [HasAzIPActiveUse] = 0)";
            continue;
          }

          if (keyCol === "[PartnerOneQuickSearch]") {
            var search = String(filterModel[keyCol])
              .replace("(", "")
              .replace(")", "");
            if (!isNaN(Number(search))) {
              tempString += " AND ([PartnerOne ID]  LIKE '%" + search + "%')";
            } else {
              tempString += " AND ([PartnerOne Name] LIKE '%" + search + "%')";
            }
            continue;
          }

          if (keyCol === "[PartnerQuickSearch]") {
            var search = String(filterModel[keyCol])
              .replace("(", "")
              .replace(")", "");
            if (!isNaN(Number(search))) {
              tempString += " AND ([Partner ID]  LIKE '%" + search + "%')";
            } else {
              tempString += " AND ([Partner Name] LIKE '%" + search + "%')";
            }
            continue;
          }

          if (keyCol === "[PartnerWebAddressQuickSearch]") {
            var search = String(filterModel[keyCol])
              .replace("(", "")
              .replace(")", "");
            tempString += " AND ([Web Address] LIKE '%" + search + "%')";
            continue;
          }
          if (keyCol === "[PartnerPrimaryContactQuickSearch]") {
            var search = String(filterModel[keyCol])
              .replace("(", "")
              .replace(")", "");
            tempString += " AND ([Primary Contact] LIKE '%" + search + "%')";
            continue;
          }
          if (keyCol === "[PartnerPhysicalAddressQuickSearch]") {
            var search = String(filterModel[keyCol])
              .replace("(", "")
              .replace(")", "");
            tempString += " AND ([Physical Address] LIKE '%" + search + "%')";
            continue;
          }
          if (keyCol === "[PartnerPhoneNumberQuickSearch]") {
            var search = String(filterModel[keyCol])
              .replace("(", "")
              .replace(")", "");
            tempString += " AND ([Phone Number] LIKE '%" + search + "%')";
            continue;
          }

          if (repeatedcols.indexOf(keyCol) > -1) {
            tempString +=
              " AND (" +
              keyCol +
              " IN " +
              filterModel[keyCol] +
              " OR " +
              keyCol.replace("1", "2") +
              " IN " +
              filterModel[keyCol] +
              " OR " +
              keyCol.replace("1", "3") +
              " IN " +
              filterModel[keyCol] +
              ")";
          } else {
            tempString += " AND " + keyCol + " IN " + filterModel[keyCol];
          }
        } else {
          if (
            keyCol === "[HasActiveCloudSubscriptionSale]" &&
            filterModel[keyCol] === "(1)"
          ) {
            if (!filterModel["[CloudSubscriptionSaleProduct]"]) {
              tempString +=
                "( [HasDynamicCRMOnlineSubscriptions] = 1 OR [HasOffice365Subscriptions] = 1 OR [HasWindowsIntuneSubscriptions] = 1 OR [HasOtherSubscriptions] = 1)";
              continue;
            } else {
              var parameters = filterModel["[CloudSubscriptionSaleProduct]"]
                .replace("(", "")
                .replace(")", "")
                .split(",");
              tempString +=
                "( " + Common.populateparamersSalesProduct(parameters);
              continue;
            }
          } else if (
            keyCol === "[HasActiveCloudSubscriptionSale]" &&
            filterModel[keyCol] === "(2)"
          ) {
            tempString +=
              "( [HasDynamicCRMOnlineSubscriptions] = 0 AND [HasOffice365Subscriptions] = 0 AND [HasWindowsIntuneSubscriptions] = 0 AND [HasOtherSubscriptions] = 0)";
            continue;
          }

          if (
            keyCol === "[HasCloudConsumptionorActiveUse]" &&
            filterModel[keyCol] === "(1)"
          ) {
            if (!filterModel["[CloudConsumptionorActiveUseProduct]"]) {
              tempString +=
                "( [HasAADPActiveUse] = 1 OR [HasAzureConsumption] = 1 OR [HasEnterpriseMobilitySuiteActiveUse] = 1 OR [HasDynamicsCRMOnlineActiveUse] = 1 OR [HasOffice365ActiveUse] = 1 OR [HasPowerBIActiveUse] = 1 OR [HasWindowsIntuneActiveUse] = 1 OR [HasAzIPActiveUse] = 1)";
              continue;
            } else {
              var parameters = filterModel[
                "[CloudConsumptionorActiveUseProduct]"
              ]
                .replace("(", "")
                .replace(")", "")
                .split(",");
              tempString +=
                " ( " + Common.populateparamersActiveUseProduct(parameters);
              continue;
            }
          } else if (
            keyCol === "[HasCloudConsumptionorActiveUse]" &&
            filterModel[keyCol] === "(2)"
          ) {
            tempString +=
              "( [HasAADPActiveUse] = 0 AND  [HasAzureConsumption] = 0 AND  [HasEnterpriseMobilitySuiteActiveUse] = 0 AND  [HasDynamicsCRMOnlineActiveUse] = 0 AND  [HasOffice365ActiveUse] = 0 AND  [HasPowerBIActiveUse] = 0 AND  [HasWindowsIntuneActiveUse] = 0 AND  [HasAzIPActiveUse] = 0)";
            var parameters = filterModel["[CloudSubscriptionSaleProduct]"]
              .replace("(", "")
              .replace(")", "")
              .split(",");
            tempString +=
              "( " + Common.populateparamersActiveUseProduct(parameters);
            continue;
          }

          if (keyCol === "[PartnerOneQuickSearch]") {
            var search: string = filterModel[keyCol]
              .replace("(", "")
              .replace(")", "");
            if (!isNaN(Number(search))) {
              tempString += " ([PartnerOne ID] LIKE '%" + search + "%')";
            } else {
              tempString += " ([PartnerOne Name] LIKE '%" + search + "%')";
            }
            continue;
          }

          if (keyCol === "[PartnerQuickSearch]") {
            var search: string = filterModel[keyCol]
              .replace("(", "")
              .replace(")", "");
            if (!isNaN(Number(search))) {
              tempString += " ([Partner ID] LIKE '%" + search + "%')";
            } else {
              tempString += " ([Partner Name] LIKE '%" + search + "%')";
            }
            continue;
          }

          if (keyCol === "[PartnerWebAddressQuickSearch]") {
            var search: string = filterModel[keyCol]
              .replace("(", "")
              .replace(")", "");
            tempString += " ([Web Address] LIKE '%" + search + "%')";
            continue;
          }
          if (keyCol === "[PartnerPrimaryContactQuickSearch]") {
            var search: string = filterModel[keyCol]
              .replace("(", "")
              .replace(")", "");
            tempString += " ([Primary Contact] LIKE '%" + search + "%')";
            continue;
          }
          if (keyCol === "[PartnerPhysicalAddressQuickSearch]") {
            var search: string = filterModel[keyCol]
              .replace("(", "")
              .replace(")", "");
            tempString += " ([Physical Address] LIKE '%" + search + "%')";
            continue;
          }
          if (keyCol === "[PartnerPhoneNumberQuickSearch]") {
            var search: string = filterModel[keyCol]
              .replace("(", "")
              .replace(")", "");
            tempString += " ([Phone Number] LIKE '%" + search + "%')";
            continue;
          }
          if (repeatedcols.indexOf(keyCol) > -1) {
            tempString +=
              " (" +
              keyCol +
              " IN " +
              filterModel[keyCol] +
              " OR " +
              keyCol.replace("1", "2") +
              " IN " +
              filterModel[keyCol] +
              " OR " +
              keyCol.replace("1", "3") +
              " IN " +
              filterModel[keyCol] +
              ")";
          } else {
            tempString += " " + keyCol + " IN " + filterModel[keyCol];
          }
        }
      } else if (filterModel[keyCol] && keyCol.charAt(0) === "@") {
        for (let i = 0; i < partnerSubconfig["parameters"].length - 1; i++) {
          if (
            keyCol.replace("@", "") === partnerSubconfig["parameters"][i].Key
          ) {
            partnerSubconfig["parameters"][i].Value = [
              [filterModel[keyCol].replace("(", "").replace(")", "")],
            ];
          }
        }
      }
    }
    if (tempString.length) {
      partnerOneFilter.Value = [[" where" + tempString]];
      partnerSubFilter.Value = [[" where" + tempString]];
    }
  }
  static populateparamersSalesProduct(parameters: { [x: string]: any; }): any {
    let paramtersString = "";
    for (const parameter in parameters) {
      switch (parameters[parameter]) {
        case "1":
          paramtersString += "[HasDynamicCRMOnlineSubscriptions] = 1 OR ";
          break;
        case "2":
          paramtersString += "[HasOffice365Subscriptions] = 1 OR ";
          break;
        case "3":
          paramtersString += " [HasWindowsIntuneSubscriptions] = 1 OR ";
          break;
        case "4":
          paramtersString += "[HasOtherSubscriptions] = 1 OR ";
          break;
        default:
          break;
      }
    }
    paramtersString += ")";
    paramtersString = paramtersString.replace("OR )", ")");
    return paramtersString;
  }
  static populateparamersActiveUseProduct(parameters: { [x: string]: any; }): any {
    let paramtersString = "";
    for (const parameter in parameters) {
      switch (parameters[parameter]) {
        case "1":
          paramtersString += "[HasAADPActiveUse] = 1 OR ";
          break;
        case "2":
          paramtersString += "[HasAzureConsumption] = 1 OR ";
          break;
        case "3":
          paramtersString += "[HasEnterpriseMobilitySuiteActiveUse] = 1 OR ";
          break;
        case "4":
          paramtersString += "[HasDynamicsCRMOnlineActiveUse] = 1 OR ";
          break;
        case "5":
          paramtersString += "[HasOffice365ActiveUse] = 1 OR ";
          break;
        case "6":
          paramtersString += "[HasPowerBIActiveUse] = 1 OR ";
          break;
        case "7":
          paramtersString += "[HasWindowsIntuneActiveUse] = 1 OR ";
          break;
        case "8":
          paramtersString += "[HasAzIPActiveUse] = 1 OR ";
          break;
        default:
          break;
      }
    }
    paramtersString += ")";
    paramtersString = paramtersString.replace("OR )", ")");
    return paramtersString;
  }
  static resetPartnerSearchParam(param: any) {
    let idx;
    for (idx = 0; idx <= param.length - 1; idx += 1) {
      param[idx].Value = [[null]];
    }
    return param;
  }
  static formatAndTrimData(content: string, len: number) {
    let formattedData = "";
    if (content.length > len) {
      formattedData = content.slice(0, len - 3) + "...";
    } else {
      formattedData = content;
    }
    return formattedData;
  }
  static FormatNumeric(sValue: any, iCommaAfterDigits: number, sExponentPart: string) {
    if (0 === sValue) {
      return "0";
    }
    if (!Common.IsDecimal(sValue)) {
      return "";
    }
    sValue += "";
    sExponentPart = Common.isSafe(sExponentPart) ? sExponentPart : "";
    iCommaAfterDigits =
      Common.isSafe(iCommaAfterDigits) && Common.isSafe(iCommaAfterDigits)
        ? iCommaAfterDigits
        : 3;
    let x = sValue.split("."),
      x1 = x[0],
      x2 = x.length > 1 ? "." + x[1] : sExponentPart,
      rgx = new RegExp("(\\d+)(\\d{" + iCommaAfterDigits + "})");
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  // Format a number by adding comma after certain digits
  static FormatNumber(sValue: number, iCommaAfterDigits: number) {
    return Common.FormatNumeric(
      sValue,
      Common.isSafe(iCommaAfterDigits) ? iCommaAfterDigits : 3,
      ""
    );
  }

  static IsDecimal(oValue: string) {
    if (Common.isSafe(oValue)) {
      return /^[+-]?\d+(\.\d+)?$/.test(oValue);
    }
  }

  static isSafe(literalToCheck: string | number) {
    if (typeof literalToCheck === "undefined" || !literalToCheck) {
      return false;
    }
    return true;
  }

  // Format a number into B,M,K format & fixing Decimal places to 1 digit
  static formatNumbers(num: any, property: boolean): string {
    if (num === "NA") return num;
    else if (num === null || num === undefined) return "0";
    const isNegative = num < 0;
    let formatted: string;
    if (isNegative) num = Math.abs(num);
    if (num <= 999 && property) formatted = num.toFixed(0) + "";
    else if (num <= 999) formatted = num.toFixed(1) + "";
    else if (num >= 1000 && num <= 99999)
      formatted = (num / 1000).toFixed(1) + "K";
    else if (num >= 100000 && num <= 99999999)
      formatted = (num / 1000000).toFixed(1) + "M";
    else if (num >= 100000000 && num <= 99999999999)
      formatted = (num / 1000000000).toFixed(1) + "bn";
    else if (num >= 100000000000 && num <= 99999999999999)
      formatted = (num / 1000000000000).toFixed(1) + "Tn";
    else formatted = num.toExponential(1);
    return isNegative ? "-" + formatted : formatted;
  }
  static findConversionUnit(value: any, formatAttribute: string): any {
    if (value === null || typeof value === "undefined") {
      return "N/A";
    }
    value = value % 1 != 0 ? parseFloat(value) : parseInt(value);
    let convertedValue;
    const trillion = 1000000000000;
    const billion = 1000000000;
    const million = 1000000;
    const thousand = 1000;
    if (value >= trillion || value <= trillion * -1) {
      convertedValue = value / trillion;
      convertedValue =
        convertedValue % 1 != 0 ? convertedValue.toFixed(2) : convertedValue;
      convertedValue = convertedValue + "T";
    } else if (value >= billion || value <= billion * -1) {
      convertedValue = value / billion;
      convertedValue =
        convertedValue % 1 != 0 ? convertedValue.toFixed(2) : convertedValue;
      convertedValue = convertedValue + "B";
    } else if (value >= million || value <= million * -1) {
      convertedValue = value / million;
      convertedValue =
        convertedValue % 1 != 0 ? convertedValue.toFixed(2) : convertedValue;
      convertedValue = convertedValue + "M";
    } else if (value >= thousand || value <= thousand * -1) {
      convertedValue = value / thousand;
      convertedValue =
        convertedValue % 1 != 0 ? convertedValue.toFixed(2) : convertedValue;
      convertedValue = convertedValue + "K";
    } else {
      convertedValue = value % 1 != 0 ? value.toFixed(2) : value;
    }
    if (formatAttribute === "currency") {
      convertedValue = "$" + convertedValue;
    } else if (formatAttribute === "percentage") {
      convertedValue += "%";
    }
    return convertedValue;
  }
  static getCommaSeparatedStringValues(obj: { [x: string]: string; }) {
    let value = "";
    for (const key in obj) {
      value = value + "'" + obj[key] + "',";
    }
    value = value.slice(0, -1);
    return value;
  }
}

export const isTokenExpiry = () => {
  let token = localStorage.getItem("powerbi_access_token");
  if (!token) {
    return true;
  }
  let tokenPayload = token.split(".")[1];
  // Convert Base64Url to Base64
  tokenPayload = tokenPayload.replace(/-/g, "+").replace(/_/g, "/");
  while (tokenPayload.length % 4) {
    tokenPayload += "=";
  }
  let decodedToken = JSON.parse(atob(tokenPayload));
  let current_time = new Date().getTime() / 1000;
  return decodedToken.exp < current_time;
}

export const signOut = async (url = "/") => {
  try {
      const response = await axios.post('/api/AuthStatus/logout', null, {
          withCredentials: true // This is important to include cookies
      });

      if (response.status === 200) {
          window.location.href = url;
      } else {
          console.error('Logout failed');
      }
  } catch (error) {
      console.error('Error during logout:', error);
  }
}
export function formatCurrentTime() {
  const d = new Date();
  const month = d.toLocaleDateString("default", { month: "numeric" });
  return month +
    "/" +
    d.getDate() +
    ", " +
    d.toLocaleTimeString("default", { hour: "2-digit", minute: "2-digit" });
}
