import React, { useEffect, useRef, useState } from 'react'
import '../Styles/Admin.css'
import AdminHorizontalTile from './AdminHorizontalTile'
import AdminNavList from './AdminNavList'
import AdminDialogueBox from './AdminDialogueBox'
import protectedAxiosInstance from './helpers/api'
import AdminReportCatalog from './AdminReportCatalog'
import AdminModify from './AdminModify'
import AdminPowerUsers from './AdminPowerUsers'

interface props {
    adminRef: React.RefObject<HTMLDivElement>;
}

const Admin:React.FC<props> = ({adminRef}) => {
    const [modifyHorizontalList, setModifyHorizontalList] = useState<boolean>(false)
    const [modifyNavList, setModifyNavList] = useState<boolean>(false)
    const [active, setActive] = useState<string>("")
    const [showDialogue, setShowDialogue] = useState<boolean>(false)
    const [editMode, setEditMode] = useState<string>("")
    const [navList, setNavList] = useState({})
    const [horizontalNavList, setHorizontalNavList] = useState({})
    const [modifyReportCatalog, setModifyReportCatalog] = useState<boolean>(false)
    const [modifyAdmins, setModifyAdmins] = useState<boolean>(false)
    const [modifyPowerUsers, setModifyPowerUsers] = useState<boolean>(false)
    const [nextTab, setNextTab] = useState<string | null>(null);

    const switchTabs = (tab: string) => {
        setModifyHorizontalList(false);
        setModifyNavList(false);
        setModifyReportCatalog(false);
        setModifyAdmins(false);
        setModifyPowerUsers(false);

        if (tab === "navList") {
            setActive("1");
            setModifyNavList(true);
        } else if (tab === "horizontalList") {
            setActive("2");
            setModifyHorizontalList(true);
        } else if (tab === "reportCatalog") {
            setActive("3");
            setModifyReportCatalog(true);
        } else if (tab === "modifyAdmins") {
            setActive("4");
            setModifyAdmins(true);
        } else if (tab === "modifyPowerUsers") {
            setActive("5");
            setModifyPowerUsers(true);
        }
    };

    const checkForUnsavedChanges = (tab: string) => {
        if (editMode !== "" && editMode !== tab) {
            setNextTab(tab);
            setShowDialogue(true);
        } else {
            switchTabs(tab);
        }
    };

    const onContinue = () => {
        if (nextTab) {
            switchTabs(nextTab);
            setNextTab(null);
        }
        setEditMode("");
        setShowDialogue(false);
    };

    const onCancel = () => {
        setShowDialogue(false)
    }

    return (
        <div className="main_container">
            <h1>Admin Portal</h1>
            <div className="">
                <button style={{ color: active === "1" ? "white" : "black", backgroundColor: active === "1" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("navList")}>Modify Nav Bar Items</button>
                <button style={{ color: active === "2" ? "white" : "black", backgroundColor: active === "2" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("horizontalList")}>Modify Report Details</button>
                <button style={{ color: active === "3" ? "white" : "black", backgroundColor: active === "3" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("reportCatalog")}>Modify Report Catalog</button>
                <button style={{ color: active === "4" ? "white" : "black", backgroundColor: active === "4" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("modifyAdmins")}>Modify Admins</button>
                <button style={{ color: active === "5" ? "white" : "black", backgroundColor: active === "5" ? "black" : "white" }} onClick={() => checkForUnsavedChanges("modifyPowerUsers")}>Modify Power BI Editors</button>
            </div>
            {modifyHorizontalList && <AdminHorizontalTile editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} />}
            {modifyNavList && <AdminNavList editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} />}
            {modifyReportCatalog && <AdminReportCatalog editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} adminRef={adminRef}/>}
            {modifyAdmins && <AdminModify editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} />}
            {modifyPowerUsers && <AdminPowerUsers editModeTab={editMode} setEditMode={(value: string) => setEditMode(value)} />}
            {
                showDialogue && <div className="unsaved-changes-dialog">

                    <div className="unsaved-changes-dialog__content" >
                        <i className="ms-Icon ms-Icon--Cancel cancle" style={{ cursor: "pointer" }} aria-hidden="true" onClick={() => setShowDialogue(false)}></i>
                        <div className='Dialogue_text'
                        >
                            <div style={{ fontFamily: "'Segoe UI Semilight',wf_segoe-ui_semilight,helvetica,arial,sans-serif", fontSize: "24px",marginBottom:"10px"}}>
                                You have unsaved changes. Do you want to continue?
                            </div>
                        </div>
                        <div className="unsaved-changes-dialog__buttons">
                            <button className="save " style={{ backgroundColor: "#0078d4" , color: "white" }} onClick={onContinue}>Continue</button>
                            <button className="save" onClick={onCancel}>Cancel</button>
                        </div>

                    </div>

                </div>

            }

        </div>
    )
}

export default Admin