import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, ListGroup } from "react-bootstrap";
import Searchreporticon from "../../Images/img/Searchreporticon.png";
import playSmallbtnicn from "../../Images/img/playSmallbtnicn.png";
import { IRecentReports } from "../Interfaces/Interfaces";
import { Constants } from "../helpers/Constants";
import Loading from "../../Images/Loading.gif";
import axios from "axios";
import SearchBar from "./SearchBar";
import Searchicon from "../../Images/img/Searchicon.png";
import protectedAxiosInstance from "../helpers/api";
import { useNavigate } from "react-router-dom";
import trainImg from "../../Images/img/train.png";
import trainingIcon from "../../Images/img/trainingicon.png";
import "./OCPLanding.css";
import { Link } from "@fluentui/react-components";
import { 
  ChartMultiple24Regular as ChartIcon, 
  Book24Regular as TrainingIcon 
} from "@fluentui/react-icons";

const env: string = (window.location.host.includes("dev") || window.location.host.includes("localhost")) ? "dev" : "non-dev";

interface props {
  recentReports: IRecentReports[];
}

interface report {
  TileText: string;
  Description: string;
  ReportURL: string;
  IsPowerBI: string;
  ContactEmail: string;
  Persona: string;
}

const UnifiedComponent: React.FC<props> = ({ recentReports }) => {
  const [reports, setReports] = useState<report[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await protectedAxiosInstance.get(
          `${window.location.origin}/api/Service/GetReportLibraryConfiguration`,
          {
            withCredentials: true,
          }
        );
        setReports(response.data.Table);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container fluid className="unified-component">
      <Row className="align-items-start">
        {/* Search Reports Section */}
        <Col md={6} className="search-reports" style={{ marginLeft: "-2px" }}>
          <div
            style={{
              height: "315px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h5 style={{ marginBottom: "0.6rem" }}>
              <ChartIcon style={{marginRight: "10px"}}/>
              Search Reports
            </h5>
            <div style={{ position: "relative" }}>
              <SearchBar reportCatalog={reports} />
              <img
                src={Searchicon}
                alt="Search Icon"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                }}
              />
            </div>
            <h6 style={{ marginTop: "1rem" }}>
              Recently Viewed Reports
            </h6>
            <div className="grid-container">
              {recentReports.length > 0 ? (
                recentReports.map((view, index) =>
                  view.tileText !== "" ? (
                    <div key={index} className="grid-item">
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default anchor behavior
                          navigate(`/view?parent=${view.tileID}`);
                        }}
                        className="no-color-change"
                      >
                        {view.tileText}
                      </Link>
                    </div>
                  ) : null
                )
              ) : (
                <p>This list is empty. Use the left menu to open a report.</p>
              )}
            </div>
            <Link
              href="#"
              className="view-report-catalog"
              onClick={() => {
                navigate("/view?parent=ReportCatalog_SearchReport");
              }}
              title="View report catalog"
            >
              <b>View Report Catalog </b>
            </Link>
          </div>
        </Col>

        {/* Training Section */}
        <Col md={6} className="demo" style={{ height: "314px" }}>
          <Col md={8}>
            <h5 className="d-flex align-items-center" style={{ marginLeft: '-15px', marginTop: '-2.5px' }}>
              <TrainingIcon style={{marginRight: "10px"}}/>
              Training Spotlight
            </h5>
            <div style={{ width: "300px", marginTop: '23px' }}>
              <p style={{ fontSize: '16px', width: '297px' }}>
                <b>How to get access to Power BI Reports?</b>
              </p>
            </div>

            <ul style={{
              marginTop: '40px',
              listStyleType: 'none',
            }} className="bullet">
              <li className="litag"
                title="Join required SGs"
              >
                <Link
                  href={Constants.JOIN_SG_URL}
                  target="_blank"
                  className="text-link"
                  style={{ color: '#115ea3' }}
                >
                  Join required SGs
                </Link>
              </li>

              <li className="litag"
                title="Join Power BI Workspace"
              >
                <Link
                  href={Constants.WORKSPACE_URL}
                  target="_blank"
                  className="text-link"
                  style={{ color: '#115ea3' }}
                >
                  Join Power BI Workspace
                </Link>
              </li>

              <li className="litag"
                title="View all training modules"
              >
                <Link
                  href="#"
                  onClick={() => {
                    navigate("../Pages/Training");
                  }}
                  target="_blank"
                  className="text-link"
                  style={{ color: '#115ea3' }}
                >
                  View all training modules
                </Link>
              </li>
            </ul>



            <div className="reportdiv">
              <Link
                href="#"
                onClick={() => {
                  navigate("/view?parent=ReportCatalog_SearchReport");
                }}
                className="view-all-link"
                title="Visit the Report Catalog to view report videos"
              >
                <b style={{ fontSize: '16px',marginBottom:"0.6rem",marginLeft:"-8px" }}>
                  Visit the Report Catalog to view report videos
                </b>
              </Link>
            </div>

          </Col>

        </Col>
      </Row>
    </Container>
  );
};

export default UnifiedComponent;