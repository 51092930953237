import React, { useState, useEffect } from 'react'
import {
    GridDots28Filled as GridIcon,
  } from "@fluentui/react-icons";

const LeftSection = () => {

    const [env,setEnv] = useState<string>("")


    const setEnvironmentName = () => {
        const url = window.location.origin.split('.')[0];

        if(url.includes('uat')){
            setEnv('UAT')
        }else if(url.includes('dev')){
            setEnv('DEV')
        }else if(url.includes('ftbi')){
            setEnv('PROD')
        }else {
            setEnv("LOCAL")
        }
    }

    useEffect(() => {
        setEnvironmentName()
    }, [])


    return <div className="navbar-header">
        <div className="navbar-top-menu navbar-home-container">

            <div className="pull-left ms-logo">
                <GridIcon style={{marginLeft: '-28px'}} />
            </div>

            <div id="logo-seperator"></div>

            <div className="navbar-sitename" id="navbar-sitename">FastTrack BI - {env}</div>

        </div>

        <div className="pull-right uh-right-icons">
            <input id="hdnsearchclose" type="hidden" value="" />
            <input id="hdnsearch" type="hidden" value="" />
            <button title="Network Navigation" className="icon icon-leading icon-list navbar-toggle pull-left collapsed" aria-expanded="false" aria-label="" type="button"></button>
        </div>
    </div>
}

export default LeftSection