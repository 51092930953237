import React, { useEffect, useState } from 'react'
import AdminDialogueBox from './AdminDialogueBox';
import axios from 'axios';
import protectedAxiosInstance from "./helpers/api";


interface props {
    report: any;
    powerBiConfig: any;
    addNewTab: (index: number) => void
    updateHorizontalNavList: () => Promise<void>
    setEditModeTab: (value: string) => void;
    setPowerBiConfig: (obj: any) => void
    createReportUrl: (obj: any) => string
    createEmbedURL: (link: string, tileId: string) => any
}

const PowerBiLinkedTab: React.FC<props> = ({ report, powerBiConfig, addNewTab, updateHorizontalNavList, setEditModeTab, setPowerBiConfig, createReportUrl, createEmbedURL }) => {
    const [editTab, setEditTab] = useState(true);
    const [presentInPowerBiConfig, setPresentInPowerBiConfig] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [intialObj, setIntialObj] = useState<any>({})
    const [initialPowerBiConfig, setInitialPowerBiConfig] = useState<any>([])
    const [addReportPage, setAddReportPage] = useState(false)
    const [reportObj, setReportObj] = useState<any>()

    const deleteFromPowerBiConfig = () => {
        const newPowerBiConfig = powerBiConfig.filter((tab: any) => tab.ContainerID !== reportObj.ContainerID)
        setPowerBiConfig(newPowerBiConfig)
        // setPresentInPowerBiConfig(false)
        setReportObj({ ...reportObj, PowerBIURL: "" })
    }

    const handleChangeReportUrl = (e: any) => {
        // console.log("Report URL ", e.target.value)
        const newReportObj = { ...reportObj, PowerBIURL: e.target.value }
        setReportObj(newReportObj)
        console.log("Report OBJ", reportObj)
        console.log("Report URL ", e.target.value)

    }

    const saveChanges = async (updatedPowerBiLinks: any, deletedPowerBiLinks: any, newPowerBiLinks: any) => {
        console.log("Updated Power Bi Links ", updatedPowerBiLinks)
        console.log("Deleted Power Bi Links ", deletedPowerBiLinks)
        console.log("New Power Bi Config ", newPowerBiLinks)
        console.log('addReportPage', addReportPage)
        if (newPowerBiLinks.length > 0) {
            try {
                protectedAxiosInstance.post('/api/service/AddReportConfiguration', newPowerBiLinks, {
                    withCredentials: true,
                })
            } catch (error) {
                console.error("Error in adding new power bi links", error)
            }
        }
        if (deletedPowerBiLinks.length > 0) {
            console.log("Deleted Power Bi Links ", deletedPowerBiLinks)
            const dataToBeDeleted = deletedPowerBiLinks
            console.log("Data to be deleted ", dataToBeDeleted)
            try {
                protectedAxiosInstance.post('/api/service/DeleteReportConfiguration', dataToBeDeleted, {
                    withCredentials: true,
                })
            } catch (error) {
                console.error("Error in deleting power bi links", error)
            }
        }
        if (updatedPowerBiLinks.length > 0) {
            try {
                protectedAxiosInstance.post('/api/service/UpdateReportConfiguration', updatedPowerBiLinks, {
                    withCredentials: true,
                })
            } catch (error) {
                console.error("Error in updating power bi links", error)
            }
        }
    }

    const handleSaveReportPage = async () => {
        updateHorizontalNavList()
    }

    const addNewPowerBiReport = () => {
        // const newPowerBiConfig = [...powerBiConfig]
        const newObj = {
            ReportID: "",
            ContainerID: report,
            PowerBIURL: "",
            SectionName: "",
        }
        console.log("In add new PowerBi Report ", newObj, reportObj)
        setReportObj(newObj)
        setPresentInPowerBiConfig(true)
    }

    const handleCancel = () => {
        console.log("In handle cancel")
        console.log("Initial Obj ", intialObj)
        console.log("Report Obj ", reportObj)
        if (intialObj.PowerBIURL === undefined) {
            setPresentInPowerBiConfig(false)
        } else {
            setReportObj(intialObj)
        }
    }



    const handleSave = async () => {
        const tmpPowerBiConfig = [...powerBiConfig]
        console.log("Report Obj ", reportObj)
        const updatedPowerBiConfig: any = [], deletedPowerBiConfig: any = [], newPowerBiConfig: any = []
        let presentInPowerBiConfig = false
        tmpPowerBiConfig.forEach((tab: any) => {
            if (tab.ContainerID === reportObj.ContainerID && tab.PowerBIURL !== reportObj.PowerBIURL) {
                updatedPowerBiConfig.push(createEmbedURL(reportObj.PowerBIURL, reportObj.ContainerID))
                // console.log("Updated Power Bi Config ", updatedPowerBiConfig)
            }
            if (tab.ContainerID === reportObj.ContainerID) {
                presentInPowerBiConfig = true
            }
        })
        //check if ReportObj is present in initialPowerBiConfig if yes then update deletedPowerBiConfig
        // if not present then add it to newPowerBiConfig

        let present = false
        initialPowerBiConfig.forEach((tab: any) => {
            if (tab.ContainerID === reportObj.ContainerID) {
                present = true
            }
        })
        if (present && !presentInPowerBiConfig) {
            deletedPowerBiConfig.push(reportObj.ContainerID)
        } else if (!present) {
            newPowerBiConfig.push(createEmbedURL(reportObj.PowerBIURL, reportObj.ContainerID))
        }



        saveChanges(updatedPowerBiConfig, deletedPowerBiConfig, newPowerBiConfig)
    }

    useEffect(() => {
        setInitialPowerBiConfig(powerBiConfig)
        console.log("Report Changed ", report)
        const present = powerBiConfig.find((tab: any) => tab.ContainerID === report)
        if (present) {
            const tmpObj = { ...present, PowerBIURL: present.PowerBIURL ? createReportUrl(present) : "" }
            setReportObj(JSON.parse(JSON.stringify(tmpObj)))
            setIntialObj(JSON.parse(JSON.stringify(tmpObj)))
            setPresentInPowerBiConfig(true)

        } else {
            setReportObj({})
            setEditTab(false)
            setIntialObj({})
            setPresentInPowerBiConfig(false)
        }
        console.log("Report Changed ", reportObj)
    }, [report])

    return (
        <div className="tableContainer">
            {!presentInPowerBiConfig && <div style={{ display: "flex", flexDirection: "column", height: "80px" }}>
                <div className="addNewContainer" onClick={() => { setEditModeTab("horizontalList"); addNewTab(0); setAddReportPage(true) }}><i className="ms-Icon ms-Icon--Add addIcon" aria-hidden="true"></i><span className="addText">Add a Power BI report with pages</span></div>
                <div className="addNewContainer" onClick={() => { setEditModeTab("horizontalList"); addNewPowerBiReport() }}><i className="ms-Icon ms-Icon--Add addIcon" aria-hidden="true"></i><span className="addText">Add a Power BI report without pages</span></div>
            </div>
            }
            {
                showDialog && <AdminDialogueBox message="Are you sure you want to save the changes?" buttonText="Save" onCancel={handleCancel} saveChanges={!addReportPage ? handleSave : handleSaveReportPage} setShowDialogue={() => setShowDialog(false)} />
            }
            {presentInPowerBiConfig && <div className="listContainer">
                <table className="navListTable">
                    <thead>
                        <tr style={{ position: "sticky", top: "0" }} className="navListTableHeader">
                            <th className="navListTableHeaderTileText">Report URL</th>
                            <th className="navListTableHeaderActions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="navListTableRow">
                            <td className="reportTableRowTileText"><input
                                className={`${editTab ? 'inputStyle' : 'inputStyleEdit'}`}
                                name="reportUrl"
                                type="text"
                                value={reportObj?.PowerBIURL || ""}
                                disabled={editTab}
                                onChange={(e) => handleChangeReportUrl(e)}
                                style={{ width: '100%' }}
                                aria-label='Report URL'
                            /></td>
                            <td className="reportTableRowActions">
                                <div className="actions">
                                    <div className="actionsComp" onClick={() => { setEditModeTab("horizontalList"); deleteFromPowerBiConfig() }} title='Delete this Tab'><i className="ms-Icon ms-Icon--Delete iconStyle" aria-hidden="true" ></i><span className="actionsText">Delete</span></div>
                                    <div className="actionsComp" onClick={() => { setEditModeTab("horizontalList"); setEditTab(false) }} title='Edit this Tab'><i className="ms-Icon ms-Icon--Edit iconStyle" aria-hidden="true" ></i><span className="actionsText">Edit</span></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="buttonContainer">
                    <button onClick={() => setShowDialog(true)}
                        disabled={reportObj?.PowerBIURL === "" || reportObj?.PowerBIURL === undefined}
                        style={{
                            backgroundColor: `${(reportObj?.PowerBIURL === "" || reportObj?.PowerBIURL === undefined) ? "#f5f3f3" : "#0078d4"
                                }`,
                            color: `${(reportObj?.PowerBIURL === "" || reportObj?.PowerBIURL === undefined) ? "Black" : "White"}`,
                        }}
                    >Save</button>
                    <button onClick={() => { handleCancel(); setEditTab(true) }}>Cancel</button>
                </div>
            </div>

            }
            {/* <div className="buttonContainer">
            <button onClick={() => setShowDialog(true)}>Save</button>
            <button>Cancel</button>
            </div> */}


        </div>
    )

}
export default PowerBiLinkedTab