import React, { useEffect, useState } from "react";
import DataRefresh from "./DataRefresh";
import hat from "../Images/hat_1.svg";
import support from "../Images/Support_1.svg";
import uservoice from "../Images/UserVoice_1.svg";
import user from "../Images/NoProfileUser.png";
import axios from "axios";
import { signOut } from "./helpers/Common";
import { useNavigate } from "react-router-dom";
import { VideoClip28Regular as VideoIcon } from "@fluentui/react-icons";
import { ClockAlarm24Regular as ClockIcon } from "@fluentui/react-icons";
import {
  ChartMultiple24Regular as ChartIcon,
  Book24Regular as TrainingIcon,
  QuestionCircle24Regular as QuestionIcon,
  PersonSupport24Regular as UserVoiceIcon,
  PersonCircle24Regular as AccountIcon,
} from "@fluentui/react-icons";
import {
  Avatar,
  Button,
  Link,
  Persona,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Tooltip,
} from "@fluentui/react-components";

const RightSection = () => {
  const [showUserInformation, setShowUserInformation] = useState(false);
  const [userImage, setUserImage] = useState<string>(user);
  const userDetails = localStorage.getItem("user");
  const userName = userDetails ? JSON.parse(userDetails).displayName : "";
  const userEmail = userDetails ? JSON.parse(userDetails).mail : "";
  const navigate = useNavigate();
  const fetchUserImage = (accessToken: any) => {
    let imgUrl;
    axios
      .get("https://graph.microsoft.com/v1.0/me/photo/$value", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response !== undefined)
          imgUrl = window.URL.createObjectURL(response.data);
        else imgUrl = user;
        setUserImage(imgUrl);
      })
      .catch((result) => {
        console.error("Error", result);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") !== null)
      fetchUserImage(localStorage.getItem("accessToken"));
  }, []);

  return (
    <div className="HeaderRightSection">
      <Button
        aria-label="Training"
        title="Training"
        appearance="transparent"
        shape="square"
        style={{ 
          borderRight: "1px solid #fff", 
          float: "left", 
          minWidth: "0px",
          padding: "4px 12px"
        }}
        onClick={() => {
          navigate("../Pages/Training");
        }}
        size="medium"
      >
        <TrainingIcon />
      </Button>

      <Button
        aria-label="Report Support"
        title="Report Support"
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        appearance="transparent"
        shape="square"
        style={{
          borderRight: "1px solid #fff",
          float: "left",
          minWidth: "0px",
          padding: "5px 12px"
        }}
        size="large"
        href="https://aka.ms/FTReportFeedback"
      >
        <QuestionIcon />
      </Button>    

      <Button
        aria-label="Portal Support"
        title="Portal Support"
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        appearance="transparent"
        shape="square"
        style={{
          borderRight: "1px solid #fff",
          float: "left",
          minWidth: "0px",
          padding: "5px 12px"
        }}
        size="large"
        href="https://o365fasttrack.visualstudio.com/FastTrack/_workitems/create/User%20Story?templateId=53ffec7e-7121-4659-931f-942584acbf74&ownerId=5fff08e1-03c3-4645-9021-7ba3db8321f4"
      >
        <UserVoiceIcon />
      </Button>
      
      <div className="Settings" id="rightborder">
        <Popover withArrow>
          <PopoverTrigger disableButtonEnhancement>
            <Avatar
              style={{ marginTop: "-3px" }}
              color="colorful"
              name={userName}
              title="Profile"
            />
          </PopoverTrigger>

          <PopoverSurface tabIndex={-1}>
            <Persona
              size="huge"
              name={userName}
              avatar={{ color: "colorful" }}
              secondaryText={userEmail}
            />
            <br />
            <Link
              style={{ float: "right" }}
              onClick={async () => {
                await signOut();
              }}
            >
              Sign Out
            </Link>
          </PopoverSurface>
        </Popover>
      </div>
    </div>
  );
};

export default RightSection;
