import React, { useEffect, useState } from "react";
import AddNewNavItemDialogueBox from "./AddNewNavItemDialogueBox";
import { Button } from "@fluentui/react-components";
import { ArrowUp16Filled, ArrowDown16Filled, Edit16Regular, Delete16Regular, AddSquare16Regular } from "@fluentui/react-icons";

interface props {
  item: any;
  index: number;
  parentTab: string;
  inEditMode: string[];
  setEditModeTab: (value: string) => void;
  setEditMode: (value: any) => void;
  handleDelete: (index: number, isFolder: boolean) => void;
  handleAddNewTab: (index: number) => void;
  handleAddNewFolder: (index: number) => void;
  handleChangeTileText: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    tileId: string
  ) => void;
  isFolderAdditionAllowed?: boolean;
  isReportAdditionAllowed?: boolean;
  reOrder: (key: number, direction: string) => void;
}

const AdminNavListItem: React.FC<props> = ({
  item,
  index,
  parentTab,
  inEditMode,
  setEditModeTab,
  setEditMode,
  handleDelete,
  handleChangeTileText,
  handleAddNewTab,
  handleAddNewFolder,
  isFolderAdditionAllowed,
  isReportAdditionAllowed,
  reOrder,
}) => {
  const [addNewTab, setAddNewTab] = useState(false);
  const [editTab, setEditTab] = useState(true);

  const setInEditMode = () => {
    if (inEditMode.indexOf(item.tileId) == -1) {
      setEditMode([...inEditMode, item.tileId]);
    }
  };

  useEffect(() => {
    if (inEditMode.length > 0) {
      let check = false;
      inEditMode.map((id: string) => {
        if (id == item.tileId) {
          setEditTab(false);
          check = true;
        }
      });

      if (!check) {
        setEditTab(true);
      }
    } else {
      setEditTab(true);
    }
  }, [inEditMode, item]);

  useEffect(() => {
    if (item.tileText === "") {
      if (inEditMode.indexOf(item.tileId) == -1) {
        setEditMode([...inEditMode, item.tileId]);
      }
    }
    if (inEditMode.length > 0)
      inEditMode.map((id: string) => {
        if (id == item.tileId) setEditTab(false);
      });
  }, [item]);

  return (
    <tr className="navListTableRow">
      <td
        className="navListTableRowOrder"
      >
        {/* <span style={{width: "100%", display: "flex", flexDirection: "row"}}> */}
        <div className="reOrderArrowDiv">
          <Button as="a" title="Move Up" disabled={editTab} appearance="subtle" size="small" icon={<ArrowUp16Filled />} onClick={() => !editTab && reOrder(index, "up")} />
          <Button as="a" title="Move Down" disabled={editTab} appearance="subtle" size="small" icon={<ArrowDown16Filled onClick={() => !editTab && reOrder(index, "down")} />} />
        </div>
        {/* </span> */}
      </td>
      <td className="navListTableRowTileText">
        <input
          className={`${editTab ? "inputStyle" : "inputStyleEdit"}`}
          disabled={editTab}
          onChange={(e) => handleChangeTileText(e, index, item.tileId)}
          name="tileText"
          type="text"
          value={item.tileText}
          aria-label="Tile Text"
        />{" "}
      </td>
      <td className="navListTableRowType">
        {item.type ? item.type : "Report"}
      </td>
      <td className="navListTableRowActions">
        <div className="actions">
          <Button
            onClick={() => {
              setEditModeTab("navList");
              setAddNewTab(true);
            }}
            title="Add new item below this"
            icon={< AddSquare16Regular />}
            appearance="subtle"
            as="a"
          > Add </Button>
          <Button
            onClick={() => {
              setEditModeTab("navList");
              setInEditMode();
            }}
            title="Edit this item"
            icon={<  Edit16Regular />}
            appearance="subtle"
            as="a"
          > Edit </Button>
          <Button
            onClick={() => {
              setEditModeTab("navList");
              handleDelete(index, item.hasSubPages);
            }}
            title="Delete this item"
            icon={<Delete16Regular />}
            appearance="subtle"
            as="a"
          > Delete </Button>
        </div>
      </td>
      {addNewTab && (
        <AddNewNavItemDialogueBox
          addNewReport={() => handleAddNewTab(index)}
          addNewFolder={() => handleAddNewFolder(index)}
          setShowDialogue={(value) => setAddNewTab(value)}
          message="This new item will be added below the selected one. Select the type of item to add:"
          isFolderAdditionAllowed={isFolderAdditionAllowed}
          isReportAdditionAllowed={isReportAdditionAllowed}
          // Addition types will be same as adjecent item type
          // if the item type is not defined then it will be Subsection.
          additionType={item.type ? item.type : "Subsection"}
        />
      )}
    </tr>
  );
};

AdminNavListItem.defaultProps = {
  isFolderAdditionAllowed: true,
}

export default AdminNavListItem;